import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import Footer from "./Footer";
import { bgColor } from "../theme/theme";
import useMobile from "../hooks/useMobile";
import MainDetail from "./MainDetail";
import MenuIcon from "@mui/icons-material/Menu";
import { PageContext } from "../App";
import { useNavigate } from "react-router-dom";
import {
  ContactInfoContext,
  LoadingContext,
  OfficialInfoContext,
} from "../pages/CompanyProfilePage";
import { headers } from "../exports/exports";
import axios from "axios";
import { dynamicFailed } from "../hooks/useToast";

const mainDetailsData = [
  {
    title: "HOME",
    type: "home",
  },
  {
    title: "PROJECTS",
    type: "projects",
  },
  {
    title: "ABOUT US",
    type: "aboutus",
  },
];

const AllDetails = ({ setOpenDrawer }: any) => {
  const { page, setPage } = useContext(PageContext);

  const { contactInfo, setContactInfo } = useContext(ContactInfoContext);

  const { officialInfo, setOfficialInfo } = useContext(OfficialInfoContext);

  const { setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const getOfficialInfo = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/api/official-uses?sort=Order%3Aasc`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setOfficialInfo(res.data.data);
        getContactInfo();
      })
      .catch(() => {
        dynamicFailed("Server Error");
        setLoading(false);
      });
  };

  const getContactInfo = () => {
    axios
      .get(
        `${process.env.REACT_APP_strapiUrl}/api/contact-infos?sort=Order%3Aasc`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        setContactInfo(res.data.data);
        setLoading(false);
      })
      .catch(() => dynamicFailed("Server Error"));
  };

  useEffect(() => {
    (contactInfo?.length === 0 || officialInfo?.length === 0) &&
      getOfficialInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mobile = useMobile();
  return (
    <Box
      width="100%"
      height="100%"
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
      borderLeft={mobile ? "1px solid black" : "none"}
      borderRight={mobile ? "1px solid black" : "none"}
      borderColor="rgba(0,0,0,0.1)"
      bgcolor={bgColor}
    >
      <MainDetail data={mainDetailsData} setOpenDrawer={setOpenDrawer} />

      <Box
        display="flex"
        flexDirection="column"
        gap="10px"
        borderBottom={mobile ? "1px solid black" : "none"}
        borderColor="rgba(0,0,0,0.1)"
        px={mobile ? "20px" : "10px"}
        // height={mobile ? "195px" : "200px"}
        pb="10px"
        onClick={() => {
          setPage("contact");
          navigate("/contact");
          mobile && setOpenDrawer(false);
        }}
        sx={{
          cursor: page !== "contact" ? "pointer" : "default",
          position: "relative",
          "&:hover .contact-text::after": {
            width: !mobile ? "43.5%" : "unset",
          },
        }}
      >
        <Typography
          className="contact-text"
          fontSize="25px"
          color="black"
          lineHeight="25px"
          mt="10px"
          sx={{
            textDecoration: page === "contact" ? "underline" : "unset",
            position: "relative",
            "&::after": {
              content: '""',
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "0%",
              height: "2px",
              backgroundColor: "black",
              transition: "width 0.3s ease-in-out",
            },
          }}
        >
          CONTACT
        </Typography>

        <Typography
          variant="caption"
          fontWeight="700"
          mt={mobile ? "0px" : "10px"}
        >
          BEST BUILDERS & CONSTRUCTION PVT. LTD.
        </Typography>

        <Typography
          variant="caption"
          fontSize="13px"
          whiteSpace="pre-line"
          lineHeight={1.7}
        >
          {contactInfo?.map((info) => {
            return <Box key={info?.id}>{info?.attributes?.Info}</Box>;
          })}
        </Typography>
      </Box>

      {mobile && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            color: "rgba(0,0,0,0.25)",
            right: "20px",
            bottom: "35px",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={() => setOpenDrawer(false)}
        >
          <MenuIcon sx={{ width: "45px", height: "45px" }} />
        </Box>
      )}

      {mobile && <Footer />}
    </Box>
  );
};

export default AllDetails;
