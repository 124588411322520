import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CompanyProfilePage from '../pages/CompanyProfilePage';
import ErrorPage from '../pages/ErrorPage';

const Main = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<CompanyProfilePage />} />
                    <Route path="/projects" element={<CompanyProfilePage />} />
                    <Route path="/aboutus" element={<CompanyProfilePage />} />
                    <Route path="/contact" element={<CompanyProfilePage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default Main
